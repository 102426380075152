@tailwind base;
@tailwind components;

body {
  overscroll-behavior: contain;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.05);
}

input {
  -webkit-user-select: text;
  user-select: text;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

/* .modal-open {
  overflow-y: hidden;
} */

body.dark *::-webkit-scrollbar-thumb {
  @apply bg-gray-700;
}

body.light *::-webkit-scrollbar-thumb {
  @apply bg-gray-300;
}

::-webkit-scrollbar-thumb:hover {
  border: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

@layer components {
  aside.sidebar ul {
    @apply relative;
  }

  aside.sidebar ul li a + ul {
    display: none;
  }

  aside.sidebar ul li a.menu + ul:before {
    content: "";
    left: 22px;
    top: -5px;
    height: calc(100% - 30px);
    @apply absolute border-l-2 dark:border-gray-700;
  }

  aside.sidebar ul li a:not(.active) {
    /* @apply bg-opacity-5; */
  }

  aside.sidebar ul li a.active {
    /* @apply bg-opacity-5; */
  }

  aside.sidebar ul li a.active + ul {
    @apply flex;
    padding-left: 36px;
  }

  aside.sidebar ul li a.menu.active + ul li {
    @apply relative;
  }

  aside.sidebar ul li a.menu.active + ul li:before {
    content: "";
    top: 6px;
    left: -14px;
    @apply absolute h-3 w-3 rounded-bl-full border-b-2 border-l-2 dark:border-gray-700;
  }

  .stretched-link {
    @apply absolute inset-0 z-10;
  }

  .bg-disabled {
    background-image: linear-gradient(
      45deg,
      #fef2f2 22.73%,
      #f9fafb 22.73%,
      #f9fafb 50%,
      #fef2f2 50%,
      #fef2f2 72.73%,
      #f9fafb 72.73%,
      #f9fafb 100%
    );
    background-size: 31.11px 31.11px;
  }

  .dark .bg-disabled {
    background-image: linear-gradient(
      45deg,
      #7f1d1d 22.73%,
      #111827 22.73%,
      #111827 50%,
      #7f1d1d 50%,
      #7f1d1d 72.73%,
      #111827 72.73%,
      #111827 100%
    );
    background-size: 31.11px 31.11px;
  }
}

@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DotGothic16&family=Inter:wght@400;500&display=swap");

/* * {
  background: rgba(255, 0, 0, 0.2);
} */

button {
  @apply select-none;
}

.logo-white .base {
  @apply fill-blue-900;
}
.logo-white .fire {
  @apply fill-white;
}

.logo-yellow .color1 {
  @apply fill-green-300;
}
.logo-yellow .color2 {
  @apply fill-blue-900 dark:fill-white;
}

[ripple] {
  position: relative;
  overflow: hidden;
}
[ripple] .ripple--container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[ripple] .ripple--container span {
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.75;
  background-color: rgba(255, 255, 255, 0.75);
  animation: ripple 1000ms;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(3);
  }
}

hr {
  @apply border-gray-200 dark:!border-gray-800;
}

/* Firefox */
input {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-calendar-picker-indicator,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

table.table-wide {
  @apply w-full;
}

table.table-wide thead tr th {
  @apply py-2 text-left text-sm uppercase;
}

table.table-wide tbody tr td {
  @apply py-2 align-top;
}

table.table-wide tbody tr:nth-child(odd) {
  @apply bg-slate-50 transition-all duration-200 hover:bg-slate-100;
}

.dark table.table-wide thead {
  @apply bg-black/5 text-white transition-all duration-200;
}

.dark table.table-wide tbody tr:not(.divider):nth-child(odd) {
  @apply bg-black/5 text-white transition-all duration-200 hover:bg-black/10;
}

table.table-wide tbody tr:not(.divider):nth-child(even) {
  @apply transition-all duration-200 hover:bg-slate-100;
}

.dark table.table-wide tbody tr:not(.divider):nth-child(even) {
  @apply bg-black/20 text-white transition-all duration-200 hover:bg-black/25;
}

table.table-wide thead tr > *:not(:first-child):not(:last-child),
table.table-wide tbody tr > *:not(:first-child):not(:last-child) {
  @apply pr-6;
}

table.table-wide thead tr > *:first-child,
table.table-wide tbody tr > *:first-child {
  @apply pl-6;
}
table.table-wide tbody tr.divider td {
  @apply border-b border-t-2 bg-white;
}

table.table-wide thead tr > *:last-child,
table.table-wide tbody tr > *:last-child {
  @apply pr-6;
}

.shadow-specials {
  box-shadow: -17px 13px 79px -32 px #f2ba27;
}

.ProseMirror {
  @apply gap-y-4;
}
.ProseMirror h1 {
  @apply text-4xl;
}
.ProseMirror h2 {
  @apply text-2xl;
}
.ProseMirror h3 {
  @apply text-lg;
}

.ProseMirror ol {
  @apply list-decimal pl-4;
}

.ProseMirror hr {
  @apply my-4;
}

.ProseMirror ul {
  @apply list-disc pl-4;
}

.ProseMirror p {
  @apply leading-6;
}

.content-article hr {
  @apply -mx-4 my-4;
}

.content-article p {
  @apply pb-1 pt-2 lg:text-lg lg:leading-7;
}

.content-article h2 {
  @apply text-2xl lg:leading-4 mb-4 mt-8;
}

.content-article h3 {
  @apply text-lg lg:leading-4;
}

.content-article ol {
  @apply list-decimal;
}
.content-article ul {
  @apply list-disc;
}
.content-article ol li,
.content-article ul li {
  @apply mb-2 ml-5 pl-1 xl:ml-4 xl:pl-3;
}

.content-article ol.heading li {
  @apply pt-4;
}

.content-article ol.heading > li::marker,
.content-article ol.heading li span {
  @apply sticky top-0 flex w-full list-decimal bg-white xl:text-2xl;
}

.content-article ol.heading li {
  @apply lg:text-lg lg:leading-7;
}

.content-article ol.heading li ol {
  @apply list-[lower-alpha];
}

.content-article ol.heading li ol li ol {
  @apply list-[decimal-leading-zero];
}
.content-article figure.kg-width-full {
  @apply -mx-96;
}
.content-article figure.kg-width-full figcaption {
  @apply text-center text-sm;
}
.content-article a {
  @apply font-bold text-blue-500 underline;
}
.content-article .kg-card {
  @apply flex select-none flex-col;
}
.content-article .kg-card .kg-toggle-heading {
  @apply flex cursor-pointer justify-between;
}
.content-article .kg-card .kg-toggle-heading .kg-toggle-card-icon {
  @apply pointer-events-none h-6 w-6;
}
.content-article .kg-card[data-kg-toggle-state="close"] .kg-toggle-content {
  @apply hidden;
}
.content-article .kg-card.kg-embed-card {
  @apply w-full aspect-video;
}
.content-article .kg-card.kg-embed-card iframe {
  @apply w-full h-full;
}
@keyframes ringing {
  1% {
    transform: rotate(30deg) scale(1.2);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
}

.ringing {
  display: block;
  transform-origin: 50% 4px;
  animation: ringing 4s -0.1s ease-in-out infinite;
}

.words-swapped {
  @apply relative h-full w-full;
}

.words-swapped span:nth-child(1) {
  @apply flex h-full items-center justify-center;
  animation: swapWords1 7s infinite alternate;
}

.words-swapped span:nth-child(2) {
  @apply absolute inset-0 flex items-center justify-center;
  animation: swapWords2 7s infinite alternate;
}

@keyframes swapWords1 {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  63% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes swapWords2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  63% {
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
